import './App.css';
import bg1 from './assets/bg1.JPG';
import bg2 from './assets/bg2a.jpg';
import bg3 from './assets/bg3a.JPG';
import bg4 from './assets/bg4.JPG';
import bg5 from './assets/bg5a.jpg';
import bg6 from './assets/bg6b.jpg';
import bg7 from './assets/bg7a.JPG';
import bg8 from './assets/bg8a.jpg';
import bg9 from './assets/bg9a.JPG';
import bg10 from './assets/bg10.JPG';
import clicker from './assets/clicker.gif';
import runner from './assets/runner.gif';
import header from './assets/header1.JPG';
import i1 from './assets/i1.PNG';
import i2 from './assets/i2.PNG';
import i3 from './assets/i3.PNG';
import i4 from './assets/i4.PNG';
import i5 from './assets/i5.PNG';
import i6 from './assets/i6.PNG';
import Longjak from './assets/Longjak.mp4';

function App() {
  return (
    <div className="App">
      <div className="container">
        <a href="https://jup.ag/swap/SOL-4G3kNxwaA2UQHDpaQtJWQm1SReXcUD7LkT14v2oEs7rV" className="jupiter">Buy</a>
        <p className="title">SOYJAK</p>
        <p className="ca">$SOY: 4G3kNxwaA2UQHDpaQtJWQm1SReXcUD7LkT14v2oEs7rV<br/></p>
        <p className="heh">heh, you thought it was over when the dev rugged? buckle up, kiddo</p>
        <p className="nooo">NOOOOOOOOOO! the community cant just take over!!</p>
        <a href="https://soyjak.party" className="party">soyjak.party</a>
        <p className="soysoy">$SOY, $SOY, $SOY, YOS</p>
        <a href="https://www.youtube.com/@soyjakpartyvideoarchive" className="archive">soyjak party video archive</a>
        <p className="icame">i came here to have a good time but im honestly feeling so attacked right now.</p>
        <a href="https://www.reddit.com/r/copypasta" className="copypasta">r/copypasta</a>

        <p className='links'>Links</p>
        <a href="https://coinmarketcap.com/currencies/soyjak/" className="cmc">CoinMarketCap</a>
        <a href=" https://www.coingecko.com/en/coins/soyjak-2" className="cg">CoinGecko</a>
        <a href="https://t.me/Soyjak_Solana" className="i1container"><img src={i1} alt="telegram" className='icon'/></a>
        <a href="https://twitter.com/Soyjak_Solana" className="i2container"><img src={i2} alt="twitter" className='icon'/></a>
        <a href="https://www.dextools.io/app/en/solana/pair-explorer/DtTkLBvYUaYBZ7PC4vCwWfu56Zkgbf7ycEXxLhAP7Xx8?t=1721196696678" className="i3container"><img src={i3} alt="dextools" className='icon'/></a>
        <a href="https://dexscreener.com/solana/dttklbvyuaybz7pc4vcwwfu56zkgbf7ycexxlhap7xx8" className="i4container"><img src={i4} alt="dexscreener" className='icon'/></a>
        <a href="https://solscan.io/token/4G3kNxwaA2UQHDpaQtJWQm1SReXcUD7LkT14v2oEs7rV" className="i5container"><img src={i5} alt="solscan" className='icon'/></a>
        <a href="https://knowyourmeme.com/editorials/guides/who-is-soyjack-the-meme-and-wojak-character-explained" className="i6container"><img src={i6} alt="knowyourmeme" className='icon'/></a>
      </div>
      <img src={header} alt="pointjak" className='header'/>
      <img src={bg1} alt="collage" className='main'/>
      <img src={bg2} alt="collage" className='main'/>
      <img src={bg3} alt="collage" className='main'/>
      <img src={bg4} alt="collage" className='main'/>
      <img src={bg5} alt="collage" className='main'/>
      <img src={bg6} alt="collage" className='bg'/>
      <img src={runner} alt="runner" className='run'/>
      <img src={bg7} alt="collage" className='main'/>
      <img src={bg8} alt="collage" className='bg'/>
      <video src={Longjak} autoPlay muted loop playsInline type="video/mp4" preload="metadata" className='run'/>
      <img src={bg9} alt="collage" className='bg'/>
      <img src={bg10} alt="collage" className='bg'/>
      <img src={clicker} alt="clicker" className='bg'/>
      <p className="footer">$SOY is for entertainment purposes only. The original dev left and there is no formal team or roadmap. Only culture.</p>
    </div>
  );
}

export default App;
